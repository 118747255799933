import {Configuration, SuppliersApi} from '@/api';
import CustomToast from '@/components/CustomToast';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import StoreForm from '@/modules/supplier/components/StoreForm';
import {StoreAddOrUpdate} from '@/modules/supplier/models/Store';
import {SettingsPath} from '@/modules/supplier/paths';
import {BuildingStorefrontIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';

const StoreAdd = () => {
    const {currentUser} = useAuth();

    const supplierId = currentUser()?.supplierId;

    const navigate = useNavigate();

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const handleSubmit = async (store: StoreAddOrUpdate) => {
        if (!supplierId) {
            return;
        }

        try {
            await suppliersApi.addStoresToSupplier(supplierId, [{
                id: store.id,
                customerCode: store.customerCode,
            }]);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Store added successfully!'}
                    visible={t.visible}
                />
            ));

            navigate(SettingsPath.store);
        } catch (error) {
            console.error('Error while adding store:', error);
        }
    };

    return (
        <>
            <SettingsHeader
                title="Add Store"
                icon={<BuildingStorefrontIcon className="w-6 h-6"/>}
            />

            <StoreForm
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default StoreAdd;
