import {SettingsPath} from '@/modules/retailer/paths';
import {Section, default as SettingsSettings} from '@/modules/settings/pages/Settings';
import {
    BellIcon,
    BuildingOfficeIcon,
    BuildingStorefrontIcon,
    CubeIcon,
    LanguageIcon,
    LockClosedIcon,
    TruckIcon,
    UserIcon,
} from '@heroicons/react/24/outline';
import {Outlet} from 'react-router-dom';

const sections: Section[] = [
    {href: SettingsPath.profile, title: 'Profile', icon: UserIcon},
    {href: SettingsPath.notification, title: 'Notifications', icon: BellIcon},
    {href: SettingsPath.language, title: 'Language', icon: LanguageIcon},
    {href: SettingsPath.password, title: 'Change Password', icon: LockClosedIcon},
    {href: SettingsPath.businessAddress, title: 'Business Address', icon: BuildingOfficeIcon},
    {href: SettingsPath.deliveryAddress, title: 'Delivery Address', icon: BuildingStorefrontIcon},
    {href: SettingsPath.supplier, title: 'Suppliers', icon: TruckIcon},
    {href: SettingsPath.product, title: 'Products', icon: CubeIcon},
];

const Settings = () => {
    return (
        <SettingsSettings sections={sections}>
            <Outlet/>
        </SettingsSettings>
    );
};

export default Settings;
