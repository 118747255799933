import Button from '@/components/Button';
import useSearch from '@/hooks/useSearch';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import StoreTable from '@/modules/supplier/components/StoreTable';
import {SettingsStoreAddPath} from '@/modules/supplier/paths';
import {BuildingStorefrontIcon} from '@heroicons/react/24/outline';
import {PlusIcon} from '@heroicons/react/24/solid';

const StoreList = () => {
    const {
        page,
        handlePageChange,
    } = useSearch();

    return (
        <>
            <SettingsHeader
                title="Stores"
                icon={<BuildingStorefrontIcon className="w-6 h-6"/>}
            >
                <Button
                    to={SettingsStoreAddPath}
                    icon={<PlusIcon className="h-5 w-5 mr-1"/>}
                    variant="secondary"
                >
                    Add Store
                </Button>
            </SettingsHeader>

            <StoreTable
                page={page}
                onChangePage={handlePageChange}
            />
        </>
    );
};

export default StoreList;
