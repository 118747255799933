import {useState} from 'react';
import Button from '@/components/Button';
import StoreSelectBox from '@/components/StoreSelectBox';
import {Store, StoreAddOrUpdate} from '@/modules/supplier/models/Store';
import {withMinimumDelay} from '@/utils/delay';
import {withLoading} from '@/utils/loading';
import {CheckIcon} from '@heroicons/react/24/solid';
import {Controller, useForm} from 'react-hook-form';

interface StoreFormProps {
    store?: Store;
    onSubmit: (store: StoreAddOrUpdate) => Promise<void>;
}

const StoreForm = ({store, onSubmit}: StoreFormProps) => {
    const [loading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        formState: {errors},
        watch,
        setValue,
    } = useForm<StoreAddOrUpdate>({
        defaultValues: store ? {
            ...store,
            customerCode: store.customerCode || '',
        } : {
            id: '',
            customerCode: '',
        },
    });

    const storeId = watch('id');

    const handleStoreChange = (id?: string) => {
        setValue('id', id || '');
    };

    const onSubmitForm = withLoading(
        withMinimumDelay(async (data: StoreAddOrUpdate) => {
            try {
                await onSubmit(data);
            } catch (error) {
                console.error('Submission error:', error);
            }
        }, 500),
        setLoading,
    );

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
                {store && (
                    <div className="col-span-2">
                        <label htmlFor="name" className="block font-medium text-gray-700">
                            Name:
                        </label>
                        <Controller
                            name="name"
                            control={control}
                            rules={{required: 'Name is required'}}
                            render={({field}) => (
                                <input
                                    id="name"
                                    {...field}
                                    readOnly
                                    className="mt-1 p-2 border border-gray-300 rounded-md w-full bg-gray-100 text-gray-500 cursor-not-allowed"
                                />
                            )}
                        />
                        {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                    </div>
                )}
                {!store && (
                    <div className="col-span-2">
                        <StoreSelectBox
                            value={storeId}
                            onSelect={(store) => handleStoreChange(store?.id)}
                        />
                    </div>
                )}
                <div className="col-span-2">
                    <label htmlFor="customerCode" className="block font-medium text-gray-700">
                        Customer Number:
                    </label>
                    <Controller
                        name="customerCode"
                        control={control}
                        rules={{required: 'Customer code is required'}}
                        render={({field}) => (
                            <input
                                type="text"
                                id="customerCode"
                                {...field}
                                className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            />
                        )}
                    />
                    {errors.customerCode && <p className="text-red-500 text-sm">{errors.customerCode.message}</p>}
                </div>
            </div>
            <div className="mt-4 flex justify-end">
                <Button
                    type="submit"
                    icon={<CheckIcon className="h-6 w-6" aria-hidden="true"/>}
                    loadingIconClassName="h-5 w-5 mr-1"
                    variant="primary"
                    loading={loading}
                >
                    Save
                </Button>
            </div>
        </form>
    );
};

export default StoreForm;
