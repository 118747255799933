import {Configuration, SuppliersApi} from '@/api';
import useFindStoresQuery from '@/api/queries/useFindStoresQuery';
import Button from '@/components/Button';
import Confirm from '@/components/Confirm';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Pagination from '@/components/Pagination';
import {SearchProps} from '@/hooks/useSearch';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {SettingsStoreAddPath, SettingsStoreEditPath} from '@/modules/supplier/paths';
import {Link} from 'react-router-dom';

const StoreTable = ({
    searchQuery,
    page = 1,
    onChangePage = () => {},
}: SearchProps) => {
    const {currentUser} = useAuth();

    const supplierId = currentUser()?.supplierId;

    const {
        stores,
        isLoading,
        error,
        itemsPerPage,
        invalidateQuery,
    } = useFindStoresQuery(
        page,
        searchQuery,
        {supplierId},
    );

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const handleDeleteStore = async (id: string) => {
        if (!supplierId) {
            return;
        }

        try {
            await suppliersApi.removeStoresFromSupplier(supplierId, [{id}]);

            await invalidateQuery();
        } catch (error) {
            console.error('Error while deleting store:', error);
        }
    };

    if (!isLoading && !error && stores.items.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center gap-8">
                <p>No stores found.</p>

                <Button
                    to={SettingsStoreAddPath}
                    variant="primary"
                >
                    Click here to add a new store
                </Button>
            </div>
        );
    }

    return (
        <div className="table-container">
            <div className="table-wrapper">
                <table className={isLoading ? 'loading' : ''}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Customer Number</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stores.items.map((store) => {
                        if (store.id === undefined) {
                            return;
                        }
                        return (
                            <tr key={store.id}>
                                <td className="w-1/2">
                                    <Link
                                        to={SettingsStoreEditPath.withId(store.id)}
                                        className="text-blue-500 hover:underline"
                                    >
                                        {store.name}
                                    </Link>
                                </td>
                                <td>{store.customerCode}</td>
                                <td>
                                    <Confirm
                                        onConfirm={() => handleDeleteStore(store.id)}
                                        message={`Do you want to delete store: ${store.name}?`}
                                    >
                                        {(handleClick) => (
                                            <Button
                                                onClick={handleClick}
                                                variant="delete"
                                                size="xs"
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </Confirm>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {isLoading ? (
                    <div role="status" className="loader">
                        <SpinnerIcon/>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : ''}
            </div>
            <div className="table-bottom">
                <Pagination
                    currentPage={page}
                    itemsPerPage={itemsPerPage}
                    totalCount={stores.totalCount || 0}
                    estimatedTotalCount={stores.estimatedTotalCount || 0}
                    onClick={onChangePage}
                />
            </div>
        </div>
    );
};

export default StoreTable;
