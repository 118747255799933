import {useEffect, useState} from 'react';
import {Configuration, StoresApi} from '@/api';
import {StoreFilters} from '@/api/StoresApi';
import {EmptyResult} from '@/models/Pagination';
import {Store, Stores} from '@/models/Store';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {setPaginationResultIfNotLoading} from '@/utils/pagination';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const initialState = EmptyResult<Store>();

interface FindStoresQueryResult {
    stores: Stores;
    isLoading: boolean;
    error: Error | null;
    itemsPerPage: number;
    invalidateQuery: () => Promise<void>;
}

const useFindStoresQuery = (
    page: number,
    searchQuery?: string,
    filters?: Partial<StoreFilters>,
): FindStoresQueryResult => {
    const queryClient = useQueryClient();

    const [stores, setStores] = useState<Stores>(initialState);

    const storesApi = new StoresApi(new Configuration(useAuth));

    const itemsPerPage = 10;

    const {
        isLoading,
        data,
        isFetching,
        error,
    } = useQuery({
        queryKey: ['stores', page, searchQuery, filters],
        queryFn: () => {
            return storesApi.findStores(
                searchQuery,
                filters,
                page * itemsPerPage - itemsPerPage,
                itemsPerPage,
            );
        },
    });

    useEffect(() => {
        setPaginationResultIfNotLoading(data, setStores, isLoading);
    }, [data, isLoading, isFetching]);

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === 'stores',
        });
    };

    return {
        stores,
        isLoading,
        error,
        itemsPerPage,
        invalidateQuery,
    };
};

export default useFindStoresQuery;
