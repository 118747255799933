import {StoreAddressType} from '@/models/Store';
import {UserRole} from '@/models/User';
import PrivateRoute from '@/modules/auth/components/PrivateRoute';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import Cart from '@/modules/retailer/pages/Cart';
import OrderDetails from '@/modules/retailer/pages/OrderDetails';
import OrderList from '@/modules/retailer/pages/OrderList';
import OrderReview from '@/modules/retailer/pages/OrderReview';
import OrderSuccess from '@/modules/retailer/pages/OrderSuccess';
import Address from '@/modules/retailer/pages/Settings/Address';
import Settings from '@/modules/retailer/pages/Settings/Settings';
import SupplierAdd from '@/modules/retailer/pages/Settings/SupplierAdd';
import SupplierEdit from '@/modules/retailer/pages/Settings/SupplierEdit';
import SupplierList from '@/modules/retailer/pages/Settings/SupplierList';
import Shop from '@/modules/retailer/pages/Shop';
import {
    CartPath,
    OrderDetailsPath,
    OrderListPath,
    OrderReviewPath,
    OrderSuccessPath,
    SettingsPath,
    SettingsProductAddPath,
    SettingsProductEditPath,
    SettingsProductImportPath,
    SettingsSupplierAddPath,
    SettingsSupplierEditPath,
    ShopPath,
} from '@/modules/retailer/paths';
import Language from '@/modules/settings/pages/Language';
import Notification from '@/modules/settings/pages/Notification';
import Password from '@/modules/settings/pages/Password';
import ProductAdd from '@/modules/settings/pages/ProductAdd';
import ProductEdit from '@/modules/settings/pages/ProductEdit';
import ProductImport from '@/modules/settings/pages/ProductImport/ProductImport';
import ProductList from '@/modules/settings/pages/ProductList';
import Profile from '@/modules/settings/pages/Profile';
import {Routes as ReactRoutes, Route} from 'react-router-dom';

const Routes = () => {
    const {role} = useAuth();

    return (
        <ReactRoutes>
            <Route element={<PrivateRoute/>}>
                <Route path={CartPath} Component={Cart}/>
                <Route path={OrderReviewPath} Component={OrderReview}/>
                <Route path={OrderSuccessPath.path} Component={OrderSuccess}/>
                <Route path={ShopPath.path} Component={Shop}/>
                <Route path={OrderDetailsPath.path} Component={OrderDetails}/>
                <Route path={OrderListPath} Component={OrderList}/>
                {role === UserRole.Retailer && (
                    <Route Component={Settings}>
                        <Route path={SettingsPath.profile} Component={Profile}/>
                        <Route path={SettingsPath.address} Component={Address}/>
                        <Route
                            path={SettingsPath.businessAddress}
                            element={<Address addressType={StoreAddressType.BUSINESS}/>}
                        />
                        <Route
                            path={SettingsPath.deliveryAddress}
                            element={<Address addressType={StoreAddressType.DELIVERY}/>}
                        />
                        <Route path={SettingsPath.language} Component={Language}/>
                        <Route path={SettingsPath.notification} Component={Notification}/>
                        <Route path={SettingsPath.password} Component={Password}/>
                        <Route path={SettingsPath.supplier} Component={SupplierList}/>
                        <Route path={SettingsSupplierEditPath.path} Component={SupplierEdit}/>
                        <Route path={SettingsSupplierAddPath} Component={SupplierAdd}/>
                        <Route path={SettingsPath.product} Component={ProductList}/>
                        <Route path={SettingsProductEditPath.path} Component={ProductEdit}/>
                        <Route path={SettingsProductAddPath} Component={ProductAdd}/>
                        <Route path={SettingsProductImportPath} Component={ProductImport}/>
                    </Route>
                )}
            </Route>
        </ReactRoutes>
    );
};

export {Routes as RetailerRoutes};

export default Routes;
