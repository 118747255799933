import {Configuration, SuppliersApi} from '@/api';
import useGetStoreQuery from '@/api/queries/useGetStoreQuery';
import CustomToast from '@/components/CustomToast';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import SettingsHeader from '@/modules/settings/pages/SettingsHeader';
import StoreForm from '@/modules/supplier/components/StoreForm';
import {StoreAddOrUpdate} from '@/modules/supplier/models/Store';
import {BuildingStorefrontIcon} from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import {useParams} from 'react-router-dom';

const StoreEdit = () => {
    const {currentUser} = useAuth();
    const {storeId} = useParams();
    const supplierId = currentUser()?.supplierId;

    const {
        isLoading,
        store,
        invalidateQuery,
    } = useGetStoreQuery(storeId, supplierId);

    const suppliersApi = new SuppliersApi(new Configuration(useAuth));

    const handleSubmit = async (storeUpdate: StoreAddOrUpdate) => {
        if (!supplierId || !store) {
            return;
        }

        try {
            await suppliersApi.addStoresToSupplier(supplierId, [{
                id: storeUpdate.id,
                customerCode: storeUpdate.customerCode,
            }]);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={'Store updated successfully!'}
                    visible={t.visible}
                />
            ));

            await invalidateQuery();
        } catch (error) {
            console.error('Error while updating store:', error);
        }
    };

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!store) {
        return;
    }

    return (
        <>
            <SettingsHeader
                title="Edit Store"
                icon={<BuildingStorefrontIcon className="w-6 h-6"/>}
            />

            <StoreForm
                store={store}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default StoreEdit;
