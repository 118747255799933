import {Configuration, StoresApi} from '@/api';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import {useQuery, useQueryClient} from '@tanstack/react-query';

const useGetStoreQuery = (storeId?: string, supplierId?: string) => {
    const queryClient = useQueryClient();

    const storesApi = new StoresApi(new Configuration(useAuth));

    const {
        isLoading,
        data: store,
        error,
    } = useQuery({
        queryKey: ['store', storeId],
        queryFn: () => {
            if (!storeId) return null;
            return supplierId
                ? storesApi.getStoreAndCustomerCode(storeId, supplierId)
                : storesApi.getStore(storeId);
        },
        enabled: Boolean(storeId), // Disable the query if storeId is not available
    });

    const invalidateQuery = async () => {
        return queryClient.invalidateQueries({queryKey: ['store', storeId]});
    };

    return {
        store,
        isLoading,
        error,
        invalidateQuery,
    };
};

export default useGetStoreQuery;
