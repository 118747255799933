import {useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/24/outline';

const columnDescriptions = [
    {name: 'Product Code', description: 'A unique identifier for the product within your system.', required: true},
    {name: 'Product Name (Required)', description: 'The name of the product, which is mandatory for identification and display purposes.', required: true},
    {name: 'Product Description', description: 'A detailed description of the product, including key features and benefits.', required: false},
    {name: 'Length (cm)', description: 'The length of the product in centimeters.', required: false},
    {name: 'Width (cm)', description: 'The width of the product in centimeters.', required: false},
    {name: 'Height (cm)', description: 'The height of the product in centimeters.', required: false},
    {name: 'Weight (kg)', description: 'The weight of the product in kilograms.', required: false},
    {name: 'Quantity', description: 'The number of individual units contained within a SKU.', required: false},
    {name: 'MOQ', description: 'The minimum order quantity required for purchase.', required: false},
    {name: 'Product GTIN', description: 'The Global Trade Item Number (GTIN) for the product, used for barcode and global identification.', required: false},
    {name: 'VAT Rate', description: 'The applicable Value Added Tax rate for the product.', required: false},
    {name: 'Product Category Name', description: 'The name of the category to which the product belongs.', required: false},
    {name: 'Product Category Description', description: 'A description of the product category, including its characteristics or grouping criteria.', required: false},
    {name: 'Price', description: 'The price of the product in the specified currency.', required: false},
    {name: 'Currency Code', description: 'The ISO 4217 currency code for the price, e.g., USD, EUR.', required: false},
    {name: 'Customer Code', description: 'A unique code that identifies the customer for associating a specific price with.', required: false},
];

const FieldDescriptions = () => {
    const [isTableOpen, setIsTableOpen] = useState(true);

    const toggleTable = () => {
        setIsTableOpen(!isTableOpen);
    };

    return (
        <div className="mt-8">
            <div className="flex items-center justify-start gap-3">
                <h2 className="text-lg font-medium text-gray-900">Template Field Descriptions</h2>
                <button
                    className="flex items-center space-x-2 p-1 bg-blue-500 text-white rounded-full"
                    onClick={toggleTable}
                >
                    {isTableOpen ? (
                        <ChevronUpIcon className="h-5 w-5"/>
                    ) : (
                        <ChevronDownIcon className="h-5 w-5"/>
                    )}
                </button>
            </div>
            <div
                className={`mt-4 overflow-hidden transition-all duration-300 ${isTableOpen ? 'max-h-screen' : 'max-h-0'}`}
            >
                <table className="table-auto border-collapse border border-gray-300 w-full text-sm text-gray-800">
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2 text-left">Column Name</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Description</th>
                        <th className="border border-gray-300 px-4 py-2 text-left">Required</th>
                    </tr>
                    </thead>
                    <tbody>
                    {columnDescriptions.map((col, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="border border-gray-300 px-4 py-2">{col.name}</td>
                            <td className="border border-gray-300 px-4 py-2">{col.description}</td>
                            <td className="border border-gray-300 px-4 py-2 text-center">
                                {col.required ? (
                                    <span className="inline-block px-3 py-1 text-xs font-semibold text-white bg-blue-500 rounded-full">
                                        Required
                                    </span>
                                ) : (
                                    <span className="inline-block px-3 py-1 text-xs font-semibold text-gray-600 bg-gray-200 rounded-full text-nowrap">
                                        Not Required
                                    </span>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FieldDescriptions;
