import {UserRole} from '@/models/User';
import PrivateRoute from '@/modules/auth/components/PrivateRoute';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import Language from '@/modules/settings/pages/Language';
import Notification from '@/modules/settings/pages/Notification';
import Password from '@/modules/settings/pages/Password';
import {default as SettingsProductAdd} from '@/modules/settings/pages/ProductAdd';
import {default as SettingsProductEdit} from '@/modules/settings/pages/ProductEdit';
import ProductImport from '@/modules/settings/pages/ProductImport/ProductImport';
import {default as SettingsProductList} from '@/modules/settings/pages/ProductList';
import Profile from '@/modules/settings/pages/Profile';
import {
    CalendarAdd,
    CalendarEdit,
    CalendarEventAdd,
    CalendarEventEdit,
    CalendarEventList,
    CalendarList,
    InventoryList,
    PriceAdd,
    PriceEdit,
    PriceList,
    PriceListAdd,
    PriceListEdit,
    PriceListList,
    ProductAdd,
    ProductCategoryAdd,
    ProductCategoryEdit,
    ProductCategoryList,
    ProductEdit,
    ProductList,
    ProductListAdd,
    ProductListEdit,
    ProductListList,
    ProductListProductAdd,
    ProductListProductList,
    ProductVariantAdd,
    ProductVariantEdit,
    ProductVariantList,
    PromotionAdd,
    PromotionEdit,
    PromotionList,
    Settings,
    WarehouseAdd,
    WarehouseEdit,
    WarehouseList,
} from '@/modules/supplier/pages';
import Address from '@/modules/supplier/pages/Settings/Address';
import StoreAdd from '@/modules/supplier/pages/Settings/StoreAdd';
import StoreEdit from '@/modules/supplier/pages/Settings/StoreEdit';
import StoreList from '@/modules/supplier/pages/Settings/StoreList';
import {
    CalendarAddPath,
    CalendarEditPath,
    CalendarEventAddPath,
    CalendarEventEditPath,
    CalendarEventListPath,
    CalendarListPath,
    InventoryListPath,
    PriceAddPath,
    PriceEditPath,
    PriceListAddPath,
    PriceListEditPath,
    PriceListListPath,
    PriceListPath,
    PriceListPriceAddPath,
    PriceListPriceEditPath,
    PriceListPriceListPath,
    ProductAddPath,
    ProductCategoryAddPath,
    ProductCategoryEditPath,
    ProductCategoryListPath,
    ProductCategoryProductListPath,
    ProductEditPath,
    ProductListAddPath,
    ProductListEditPath,
    ProductListListPath,
    ProductListPath,
    ProductListProductAddPath,
    ProductListProductListPath,
    ProductProductVariantAddPath,
    ProductProductVariantEditPath,
    ProductProductVariantListPath,
    ProductVariantAddPath,
    ProductVariantEditPath,
    ProductVariantListPath,
    PromotionAddPath,
    PromotionEditPath,
    PromotionListPath,
    SettingsPath,
    SettingsProductAddPath,
    SettingsProductEditPath,
    SettingsProductImportPath,
    SettingsStoreAddPath,
    SettingsStoreEditPath,
    WarehouseAddPath,
    WarehouseEditPath,
    WarehouseListPath,
} from '@/modules/supplier/paths';
import {Routes as ReactRoutes, Route} from 'react-router-dom';

const Routes = () => {
    const {role} = useAuth();

    return (
        <ReactRoutes>
            <Route element={<PrivateRoute/>}>
                <Route path={CalendarListPath} Component={CalendarList}/>
                <Route path={CalendarAddPath} Component={CalendarAdd}/>
                <Route path={CalendarEditPath.path} Component={CalendarEdit}/>
                <Route path={CalendarEventListPath.path} Component={CalendarEventList}/>
                <Route path={CalendarEventAddPath.path} Component={CalendarEventAdd}/>
                <Route path={CalendarEventEditPath.path} Component={CalendarEventEdit}/>
                <Route path={InventoryListPath} Component={InventoryList}/>
                <Route path={ProductListPath} Component={ProductList}/>
                <Route path={ProductEditPath.path} Component={ProductEdit}/>
                <Route path={ProductAddPath} Component={ProductAdd}/>
                <Route path={ProductCategoryListPath} Component={ProductCategoryList}/>
                <Route path={ProductCategoryEditPath.path} Component={ProductCategoryEdit}/>
                <Route path={ProductCategoryAddPath} Component={ProductCategoryAdd}/>
                <Route path={ProductCategoryProductListPath.path} Component={ProductList}/>
                <Route path={ProductListListPath} Component={ProductListList}/>
                <Route path={ProductListEditPath.path} Component={ProductListEdit}/>
                <Route path={ProductListAddPath} Component={ProductListAdd}/>
                <Route path={ProductListProductAddPath.path} Component={ProductListProductAdd}/>
                <Route path={ProductListProductListPath.path} Component={ProductListProductList}/>
                <Route path={ProductProductVariantListPath.path} Component={ProductVariantList}/>
                <Route path={ProductProductVariantEditPath.path} Component={ProductVariantEdit}/>
                <Route path={ProductProductVariantAddPath.path} Component={ProductVariantAdd}/>
                <Route path={ProductVariantListPath} Component={ProductVariantList}/>
                <Route path={ProductVariantEditPath.path} Component={ProductVariantEdit}/>
                <Route path={ProductVariantAddPath} Component={ProductVariantAdd}/>
                <Route path={PriceListListPath} Component={PriceListList}/>
                <Route path={PriceListEditPath.path} Component={PriceListEdit}/>
                <Route path={PriceListAddPath} Component={PriceListAdd}/>
                <Route path={PriceListPriceListPath.path} Component={PriceList}/>
                <Route path={PriceListPriceEditPath.path} Component={PriceEdit}/>
                <Route path={PriceListPriceAddPath.path} Component={PriceAdd}/>
                <Route path={PriceListPath} Component={PriceList}/>
                <Route path={PriceEditPath.path} Component={PriceEdit}/>
                <Route path={PriceAddPath} Component={PriceAdd}/>
                <Route path={PromotionListPath} Component={PromotionList}/>
                <Route path={PromotionEditPath.path} Component={PromotionEdit}/>
                <Route path={PromotionAddPath} Component={PromotionAdd}/>
                {role === UserRole.Supplier && (
                    <Route Component={Settings}>
                        <Route path={SettingsPath.profile} Component={Profile}/>
                        <Route path={SettingsPath.address} Component={Address}/>
                        <Route path={SettingsPath.businessAddress} Component={Address}/>
                        <Route path={SettingsPath.language} Component={Language}/>
                        <Route path={SettingsPath.notification} Component={Notification}/>
                        <Route path={SettingsPath.password} Component={Password}/>
                        <Route path={SettingsPath.product} Component={SettingsProductList}/>
                        <Route path={SettingsProductEditPath.path} Component={SettingsProductEdit}/>
                        <Route path={SettingsProductAddPath} Component={SettingsProductAdd}/>
                        <Route path={SettingsPath.store} Component={StoreList}/>
                        <Route path={SettingsStoreEditPath.path} Component={StoreEdit}/>
                        <Route path={SettingsStoreAddPath} Component={StoreAdd}/>
                        <Route path={SettingsProductEditPath.path} Component={ProductEdit}/>
                        <Route path={SettingsProductAddPath} Component={ProductAdd}/>
                        <Route path={SettingsProductImportPath} Component={ProductImport}/>
                    </Route>
                )}
                <Route path={WarehouseListPath} Component={WarehouseList}/>
                <Route path={WarehouseAddPath} Component={WarehouseAdd}/>
                <Route path={WarehouseEditPath.path} Component={WarehouseEdit}/>
            </Route>
        </ReactRoutes>
    );
};

export {Routes as SupplierRoutes};
export default Routes;
