import {Configuration, StoresApi} from '@/api';
import SelectBox, {
    Item,
    MultiSelectProps,
    OtherSelectBoxProps,
    SingleSelectProps,
} from '@/components/SelectBox';
import {Store} from '@/models/Store';
import {useAuth} from '@/modules/auth/contexts/AuthContext';

type StoreSelectBoxProps<T extends Item> = (
    SingleSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple?: false;
        customerId?: string;
    }
) | (
    MultiSelectProps<T> & OtherSelectBoxProps<T> & {
        multiple: true;
        customerId?: string;
    }
);

const StoreSelectBox = ({customerId, ...props}: StoreSelectBoxProps<Store>) => {
    const storesApi = new StoresApi(new Configuration(useAuth));

    const listStores = async (): Promise<Store[]> => {
        const response = await storesApi.findStores(undefined, {customerId});
        return response.items;
    };

    const {
        label = 'Store:',
        placeholder= 'Select store',
    } = props;

    return (
        <SelectBox<Store>
            {...props}
            name="store"
            label={label}
            placeholder={placeholder}
            queryFn={listStores}
            queryKey={['store', customerId || '']}
        />
    );
};

export default StoreSelectBox;
